<template>
  <div class="">
    <b-container fluid="xl">
      <div class="px-xl-5 px-md-2 mx-lg-2">
        <b-row cols-md="2" cols="1">
          <b-col class="text-start pe-lg-5 pe-1"
            ><div>
              <img
                src="../assets/catalogue.png"
                class="mt-md-n5 book-img mx-auto text-center d-flex"
              />
              <h1 class="display-4 fw-stronger">
                Une vie plus sûre dans votre propre maison
              </h1>
              <h5 class="l-height">
                Si vous avez plus de difficulté à monter les escaliers, un
                monte-escalier peut être une solution. Vous serez monté et
                descendu en toute sécurité et confortablement. Dans notre
                brochure gratuite sur les monte-escaliers, vous pouvez lire tout
                ce qui concerne les monte-escaliers à votre guise. Cette
                brochure vous donne une idée des possibilités et des modèles,
                mais aussi de l'achat et du financement.
              </h5>
              <h5 class="l-height fw-stronger">
                Demandez notre brochure et découvrez:
              </h5>
              <ol>
                <li class="h5 l-height mb-0">Astuces et conseils</li>
                <li class="h5 l-height mb-0">
                  La plus grande gamme de marques et de modèles
                </li>
                <li class="h5 l-height mb-0">
                  Des monte-escaliers dans toutes les gammes de prix
                </li>
                <li class="h5 l-height mb-0">
                  Entrez vos coordonnées à droite et recevez la brochure
                  gratuite du monte-escalier
                </li>
              </ol>
            </div></b-col
          >
          <b-col
            ><h1 class="fw-bold mt-5">
              Comparez 17 monte-escaliers de 6 fabricants
            </h1>
            <b-button class="s-btn btn-md px-md-5 px-3 fw-bold">
              Demandez la brochure gratuite et sans engagement </b-button
            ><b-card>
              <div class="formSection d-flex flex-column">
                <h3>
                  <span class="fw-bold">OUI,</span>envoyez-moi la brochure
                  gratuite du monte-escalier
                </h3>
                <div class="m-auto">
                  <!-- <img
                    v-if="profId != null"
                    referrerpolicy="no-referrer-when-downgrade"
                    :src="`https://republish.renovationdomiciliare.fr/m/5939/1b92d98c6b07/?event=6923&unique_conversion_id=${profId}`"
                    style="width: 1px; height: 1px; border: 0px"
                  /> -->
                  <img
                    v-if="profId != null"
                    referrerpolicy="no-referrer-when-downgrade"
                    :src="`https://republish.renovationdomiciliaire.fr/m/5939/1b92d98c6b07/?event=6923&unique_conversion_id=${profId}`"
                    style="width: 1px; height: 1px; border: 0px"
                  />
                  <!-- <h3>{{ profId }}</h3>   -->
                  <p class="my-4 text-center">
                    Merci pour votre requête. Consultez dès maintenant notre
                    brochure sur les monte-escaliers!

                    <a
                      href="https://s3-eu-west-1.amazonaws.com/cdn.mobilae.fr/emag/fr_monteescalier_brochure/index.html"
                      target="_blank"
                      >Regarde maintenant</a
                    >
                  </p>
                </div>
              </div>
            </b-card></b-col
          >
        </b-row>
      </div>
    </b-container>
    <div class="hero bg-color py-4 py-md-5 mt-4">
      <b-container fluid="xl">
        <div class="px-xl-5 px-md-2 mx-lg-2">
          <b-row cols-md="2" cols="1" class="align-items-center">
            <b-col class="text-start"
              ><div>
                <h1 class="fw-stronger">
                  Trouvez le monte-escalier adapté à votre situation
                </h1>
                <h5 class="l-height">
                  Vous souhaitez remonter ou redescendre facilement ? Avec un
                  monte-escalier Mobilae, vous restez mobile, sûr et indépendant
                  à la maison. En tant que conseiller indépendant à vos côtés,
                  nous vous aidons à choisir le bon monte-escalier. Notre vaste
                  gamme ne contient que des modèles de haute qualité des
                  meilleurs fabricants. Avec vous, nous passons en revue les
                  différentes options. Nous examinons quel modèle de quel
                  fabricant convient le mieux à votre situation de vie
                  personnelle, à vos souhaits et à vos besoins. Avec 30 ans
                  d'expérience à travers l'Europe, nous veillons à ce que chaque
                  étape vers votre nouveau monte-escalier soit simple et bien
                  pensée. Du conseil à l'installation et au service client. Nos
                  garanties uniques et notre entretien annuel garantissent votre
                  satisfaction pour les années à venir.
                </h5>
              </div>
            </b-col>
            <b-col>
              <div>
                <img src="../assets/afspraak.png" class="w-100 rounded" />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AfterFormSubmited",
  computed: mapGetters(["profId"]),
};
</script>

<style lang="scss">
$enable-negative-margins: true;
.formSection {
  height: 684px;
}

.custom-select {
  appearance: revert !important;
}
.form-control {
  padding: 0.75rem 0.75rem !important;
}
.s-btn {
  padding: 1rem !important;
  width: 100%;
  background-color: #ff5a00 !important;
  border: 0 !important;
  border-radius: 15px !important;
  @media (min-width: 767px) and (max-width: 990px) {
    padding: 1rem !important;
  }
}
.book-img {
  width: 100%;
  @media (max-width: 767.9px) {
    width: 50% !important;
  }
}
.card {
  margin: 50px 0;
  border-radius: 10px !important;
}
.card-body {
  background-color: #50a89e !important;
  color: white;
  padding: 50px !important;
  border-radius: 7px;
  @media (max-width: 990px) {
    padding: 30px 15px !important;
  }
}
.opacity {
  opacity: 0.6;
}
label {
  padding: 7px 0;
}
@media (min-width: 768px) {
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
}
.bg-color {
  background-color: #b9e6ff !important;
}
.l-height {
  line-height: 1.9 !important;
}
</style>
